import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    data: {}
  },
  mutations: {
    SET_DATA: function(state, payload) {
      state.data = payload;
    },
    SET_SINGLE_DATA: function(state, payload) {
      state[payload.field] = payload.data;
    }
  },
  actions: {
    LOAD_DATA: function({ commit, state }, payload) {
      if (state.people.length === 0) {
        fetch(`${process.env.VUE_APP_ENDPOINT}/jsonapi/node/` + payload.node_type)
          .then((response) => {
            return response.json();
          })
          .then((json) => {
            commit(`SET_DATA`, json.data);
          });
      }
    }
  },
  modules: {
  }
})
