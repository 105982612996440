<template>
  <div class="template-page">
    <header-intro :title="pagedata.title" :subtitle="summary" :backimg="this.bannerimg"></header-intro>
    <main-content>
      <slot>
      </slot>

      <br>
      <br>

      <div class="video-container" v-if="fulldata && fulldata.attributes && fulldata.attributes.field_youtube_video">
        <iframe allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen" 
          frameborder="0"
          class="video" 
          :src="'https://www.youtube.com/embed/' + this.fulldata.attributes.field_youtube_video">
        </iframe>
      </div>

      <br>
      <br>

      <h3 v-if="fulldata && fulldata.field_attachments && fulldata.field_attachments.length">Attachment/s</h3>
      <div v-if="fulldata && fulldata.field_attachments && fulldata.field_attachments.length">
          <div v-for="(attachment, index) in fulldata.field_attachments" :key="index">
              <a v-if="fulldata.relationships.field_attachments.data[index].meta.description"
                  class="cta attachment" :href="fileSrc(attachment)" target="_blank" download>
                  {{ fulldata.relationships.field_attachments.data[index].meta.description }}</a>
              <a v-else class="cta attachment" :href="fileSrc(attachment)" target="_blank" download>
                  {{ attachment.attributes.filename }}
              </a>
          </div>
      
      
      </div>

      <page-images v-if="fulldata" :attachmentData="fulldata"></page-images>
    </main-content>
  </div>
</template>

<script>
import HeaderIntro from '../components/HeaderIntro.vue';
import MainContent from '../components/MainContent.vue';
import PageImages from '../components/PageImages.vue'

export default {
  name: 'basic-page',
  components: {
    HeaderIntro,
    MainContent,
    PageImages
  },
  data: () => {
    return { }
  },
  mounted(){
    //console.log(this.fulldata)
   },
  props: {
    pagedata: Object,
    bannerimg: String,
    fulldata: Object
  },
  methods: {
    fileSrc(att) {
      return process.env.VUE_APP_ENDPOINT + att.attributes.uri.url
    }
  },
  computed: {
    summary() {
      
      if(this.pagedata.body && this.pagedata.body[0])
        return this.pagedata.body[0].summary
      else return ''
    }
  }
}
</script>

<style lang="scss">

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  margin-top: 40px;

  iframe { 
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

</style>
