<template>
    <div>
        <div v-if="this.attachmentData.field_page_image">
            <img class="image-banner" :src="endpoint + this.attachmentData.field_page_image.attributes.uri.url" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'page-images',
        components: {},
        props: {
            attachmentData: Object
        },
        data: () => {
            return {}
        },
        computed: {
            endpoint (){
                return process.env.VUE_APP_ENDPOINT
            },
        },
        methods: {

        }
    }
</script>

<style lang="scss"  >
/*banner image in basic page*/
.image-banner {
    width: 100%;
    height: auto;
    max-width: 1030px;
    margin: 0px auto;
    position: relative;
    margin-top: 40px;
}
</style>