<template>
    <div class="main-content">
        <div class="step">
            <div class="container">
            </div>
        </div>
        <div class="container">
            <slot>
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'main-content',
    props: {
    }
}
</script>

<style scoped lang="scss">
@import "../styles/colors.scss";

.main-content {
    padding:0rem 0rem 2rem;
    position: relative;
    background-position: bottom right;
    //background-image: url("../assets/filigrana-white.png");
}

/* econtent overlap */
.main-content {
    margin-top: -60px;
    min-height: 500px;
    background: #fff url("../assets/filigrana-white.png");
    background-position: bottom right;
    background-repeat: no-repeat;
    .step {
        background-color: transparent;
        position: relative;
        top: -65px;
        overflow: hidden;
        padding: 0;
        margin-bottom: -65px;
        height: 65px;
        .container {
            height: 100%;
            background-color: #fff;
            border-top: 10px solid $mainColor;
            &:before {
                border-top: 10px solid $mainColor;
                content: "";
                background-color: #fff;
                width: 100%;
                height: 65px;
                position: absolute;
                top: 0px;
                right: 0;
            }
        }
    }
    >.container {
        > div:first-child {
            margin: 0 auto;
    }
}
}
@media (max-width: 1024px) {
    .main-content {
        background-size: 500px;
    }
}

@media (max-width: 768px) {
    .main-content {
        background-size: 400px;
    }
    .step {
        display: none;
    }
    .main-content {
        margin-top: 0px;
        .container {
            padding-top: 50px;
        }
    }
}

@media (min-width: 576px) { 
    .main-content .container:before {
        max-width: none;
    }
 }

@media (min-width: 768px) { 
    .main-content .container:before {
        max-width: 720px;
    }
 }

@media (min-width: 992px) { 
    .main-content .container:before {
        max-width: 960px;
    }
 }

@media (min-width: 1200px) { 
    .main-content .container:before {
        max-width: 1140px;
    }
}

@media (min-width: 1500px) { 
    .main-content .container:before {
        max-width: 1480px;
    }
}
</style>