<template>
    <div class="template-page">
        <basic-page :pagedata="this.pageData">
            <p>
                This page doesn't exist.
            </p>
        </basic-page>
    </div>
</template>

<script>
import BasicPage from './BasicPage.vue'

export default {
  components: { BasicPage },
    name: 'NotFound',
    data: () => {
        return {
            currentPageId: "",
            pageData: {
                title: 'Not Found'
            },
            fulldata: {},
            bannerimg: null
        }
    },
    methods: {
    },
    computed: {
    },
    mounted() {
        //console.log('not-found')
    }
}
</script>

<style lang="scss" scoped>
</style>