<template>
<header :class="{sticky : windowScrolled}">
  <nav class="navbar">
    <div class="container">

    <div class="navbar-brand">
      <router-link to="/" @click="closeMenu">
        <img src="@/assets/logo-header-white.svg" alt="logo" class="logo-desktop data-science"  />
        <img src="@/assets/logo-ds-header-dark.svg" alt="logo" class="logo-desktop data-science logo-scrolled"  />
      </router-link>

      <a target="_blank" href="https://www.sissa.it" class="navbar-brand" @click="closeMenu">
        <img src="@/assets/logo-sissa-white.png" alt="logo" class="logo-desktop sissa"  />
        <img src="@/assets/logo-sissa-dark.png" alt="logo" class="logo-desktop sissa logo-scrolled"  />
      </a>
    </div>

    <div :class="['inner-container container', menuOpen ? 'show' : '']" >
      <!-- create a list of menu routes -->
      <ul class="navbar-nav">
        <!-- for every top level menu route ... -->
        <li v-for="(item, index) of menuItems" :key="'menu' + index"
          :class="['nav-item', index === submenuOpen ? 'active' : '']"
        >
          <!-- if has children is not clickable -->
          <div v-if="item.children && item.children.length > 0" class="nav-item-content" @click="openSubmenu(index)">
            <span class="nav-link dropdown">{{ item.label }}</span>
          </div>

          <!-- if it's a top level route menu, it is a link -->
          <div v-else>
            <router-link class="nav-link" :to="item.url" @click="closeMenu">
              {{ item.label }}
            </router-link>
          </div>

          <!-- if it has children, create a list of sub links -->
          <ul
            v-if="item.children && item.children.length > 0"
            :class="['submenu', submenuOpen === index ? 'active': '']"
          >
            <li v-for="(child, index) of item.children" :key="index">
              <!-- create a clickable link for every child -->
              <div @click="closeMenu">
                <router-link :to="child.url">
                  {{ child.label }}
                </router-link>
              </div>
            </li>
          </ul>

        </li>
      </ul>
    </div>
    <div class="navbar-toggler" >
       <label for="check">
          <input type="checkbox" ref="checkboxMenu" id="check"  @click="toggleMenu"/> 
          <span></span>
          <span></span>
          <span></span>
        </label>
    </div>
</div>
  </nav>
</header>
</template>

<script>
export default {
  name: 'the-header',
  data() {
    return {
      windowScrolled:false,
      menuOpen: false,
      submenuOpen: null,
      //static json for initial menu, structure to retrieve from drupal jsonapi
      menuItems: []
    };
  },
  methods: {
    //navigation menu structure (routing) based on drupal jsonapi
    async fetchMenuItems() {
      var menu = []
      var items = []
      var ch = []

      await fetch(`${process.env.VUE_APP_ENDPOINT}/jsonapi/menu_items/front-menu`)
        .then(res => res.json())
        .then(json => items = json.data)

      //for each fetched route
      items.forEach(item => {
        ch = []

        //for each menu item with no parent
        if(item.attributes.parent === "" || item.attributes.parent === undefined) {
          //if the number of its children is 0
          if(items.filter(it => it.attributes.parent === item.id).length==0) {
            //push it into the menu as a top-level route
            menu.push({
              label: item.attributes.title,
              url: item.attributes.url
            })

          } else {
            //else search for its children
            items.filter(it => it.attributes.parent === item.id)
              //and create an array of routes
              .forEach(child => ch.push({
                label: child.attributes.title,
                url: child.attributes.url
              }))
            //then push them into the menu as a sub-level route (for that corresponding top-level menu)
            menu.push({
              label: item.attributes.title,
              children: ch
            })
          }
        }
      })

      //create the menu
      this.menuItems = menu
    },
    toggleMenu() {
      this.menuOpen ? this.closeMenu() : this.openMenu();
      this.openSubmenu(null);
    },
    openMenu() {
      document.querySelector("html").classList.add("menu-open");
      document.body.classList.add("menu-open");
      this.menuOpen = true;
    },
    closeMenu() {
      document.querySelector("html").classList.remove("menu-open");
      document.body.classList.remove("menu-open");
      this.openSubmenu(null);
      this.menuOpen = false;

      //close hamburger menu icon
      this.$refs.checkboxMenu.checked = false
    },
    //just one submenu can stay open at a time
    openSubmenu(index) {
      if (this.submenuOpen === index) {
        this.submenuOpen = null;
      } else {
        this.submenuOpen = index;
      }
    },
    handleScroll() {

      if(window.pageYOffset){
        this.windowScrolled = true
      } else {
        this.windowScrolled = false
      }
      
    }
  },
  //as page is created, fetch for backend routing
  created() {
    this.fetchMenuItems();
    window.addEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";

/*hamburger menu animation*/

label{
 display:flex;
  flex-direction:column;
  width:70px;
  cursor:pointer;
  transform: scale(0.5);
  span:nth-of-type(1){
  width:50%;
}
span:nth-of-type(2){
  width:100%;
}
span:nth-of-type(3){
  width:75%;
}
input[type="checkbox"]{
  display:none;
}
input[type="checkbox"]:checked ~ span:nth-of-type(1){
  transform-origin:bottom;
  transform:rotatez(45deg) translate(8px,0px)
}
input[type="checkbox"]:checked ~ span:nth-of-type(2){
  transform-origin:top;
  transform:rotatez(-45deg)
}
input[type="checkbox"]:checked ~ span:nth-of-type(3){
  transform-origin:bottom;
  width:50%;
  transform: translate(30px,-11px) rotatez(45deg);
}

}
label span{
  background: #fff;
  border-radius:10px;
  height:7px;
  margin: 7px 0;
  transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);
}


/*reset list style*/
header li {
  &::before{
    display: none;
  }
}

/*comportamento header on scroll*/
header nav:before {
  content: "";
  background-color: rgba(255, 255, 255);
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
  right: 0px;
  display: block;
  position: absolute;
  transition:0.3s ease;
  box-shadow: 0px 11px 20px 0px #00000012;
  transform: translateY(-100%);
  z-index: -1;
}

header.sticky {
  .logo-desktop {
    display: none;
  }
  .logo-scrolled {
    display: block;
  }
  nav:before{
    transition: 0.3s ease;
    transform: translateY(0%);
  }
  .navbar .container {
    border-bottom: 0px;
    .nav-link {
      color: #333;
      transition: 0.3s ease;
    }
  }
  .navbar-toggler {
    transition: 0.2s ease;
    &:before{
      transition: 0.3s ease;
      transform: translateY(0%);
    }
  }
}

.navbar-toggler{
  position: relative;
   &:before{
        content: "";
        background-color: $mainColor;
        width: 100%;
        height: 100%;
        z-index: 0;
        top: 0;
        right: 0px;
        display: block;
        position: absolute;
        transition:0.3s ease;
        box-shadow: 0px 11px 20px 0px #00000012;
        transform: translateY(-100%);
        z-index: -1;
    }
}

/* stile generale*/
.logo-scrolled {
  display: none;
}
header {
  .navbar {
    background: transparent;
    .container{
      border-bottom: 2px solid rgba(255, 255, 255, 0.2);
      align-items: center;
      .inner-container.container {
        left: 0;
        padding: 0;
        width: auto;
        border-bottom:0px;
        margin: 0;
      }
      .nav-link {
        font-size: 1rem;
        color: #fff;
        font-weight: 600;
      }
    }
  }
}

.navbar-brand {
  display: flex; display: -webkit-flex;
  justify-self: center;
  .logo-desktop.sissa {
    margin-left: 50px;
  }
}

.nav-item {
  padding: 10px 30px;
  cursor: pointer;
  &:after{
    background-image: url("../assets/arrow-yellow-dropdown-menu.svg");
    content: "";
    width: 11px;
    height: 6px;
    top: 15px;
    right: 5px;
    display: block;
    position: absolute;
    transition: 0.3s ease;
  }
}


@media (min-width: 992px) {
  .navbar {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .logo-mobile { display: none; }

  .navbar-nav:not(.extra-menu) {
    .nav-item {
      width: auto;
    }
  }

  .inner-container {
    .navbar-nav {
      align-items: stretch;
      justify-content: center;
      padding: 90px 0px 0px 0px;
    }
    


    
    .nav-item {
      min-height: auto;
      display: flex; display: -webkit-flex;
      align-items: center;
      padding: 0px 30px 20px 30px;
      &.active {
        border-bottom: 1px solid $mainColor;
      }

      div:first-child:not(.nav-item-content) { top: 0; }

      .nav-item-content { display: block; }
 
      .submenu {
        pointer-events: none;
        position: absolute;

        background: #fddb40 url("../assets/filigrana-white.png");
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: 200%;

        left: 2px;
        margin: 0;
        display: block;
        left: 0;
        width: 250px;
        box-shadow: 10px 9px 29px 0px #0000004d;
        top: 50px;
        padding: 20px 13px;
        transition: 0.3s ease-out;
        overflow: hidden;
        opacity: 0;
        transform: translateY(-20px);
         li {
            padding: 0;
            margin: 0;
            width: 100%;
            a {
              text-decoration: none;
              display: block;
              padding: 0.5rem 1rem 0.5rem 1rem;
              line-height: 1.5rem;
              font-weight: 600;
         
              &:hover {
                color: #fff;
              }
            }
          }
      }

      &:hover {
        .submenu {
          pointer-events: all;
          display: block;
          transition: 0.5s ease;
          transition-delay: 0s;
          opacity: 1;
          transform: translateY(0px);
        }
      }
    }
  }
}

@media (max-width:1500px){
  .navbar-brand img {
    height: 75px;
    width: auto;
  }
  .inner-container .navbar-nav {
    padding: 60px 0px 0px 0px;
  }
}

@media (max-width:1200px){
  .inner-container .nav-item {
    padding: 0px 18px 20px;
    &:after {
      right: 0;
    }
  }
  
  .navbar-brand img {
    height: 65px;
    width: auto;
  }
}

@media (max-width:992px){
  header{
    .navbar {
      .container {
        max-width: none;
        padding:0px 0px 0px 15px ;
        .inner-container.container{
          width: calc(100% + 17px);
          background-color:$mainColor;
          position: fixed;
          height: 100%;
          overflow: scroll;
          .navbar-nav {
            background-color: $mainColor;
            padding: 50px 30px 0px;
            .nav-item {
              padding: 0px 0px 20px;
              &:after {
                width: 9px;
                background-image: url("../assets/arrow-dark-dropdown-menu.svg");
              }
              .nav-link {
                color: #333;
              }
              .submenu {
                width: 100%;
                margin-top: 0;
                li{
                  margin: 0;
                  padding: 0.5rem 1rem 0.5rem 0rem;

                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width:768px){
  header .navbar {
    height:60px;
    .container .inner-container.container{
      top: 60px;
      .navbar-nav{
        padding: 30px 30px 0px;
      }
    }
  }

  .navbar-brand img {
    height: 100%;
    width: auto;
    padding: 10px 0px;
  }
 .navbar-brand> a {
        display: block;
    height: 100%;
  }

  .navbar-toggler {
    width: 60px;
  }
}


/*
modifiche a voce OPPORTUNITIES
*/

header .navbar .container .inner-container.container .navbar-nav .nav-item:nth-child(6) {
  a {
    display: inline-block;
    background: #112342;
    padding: 5px 16px 8px 13px;
    text-decoration: none;
    color: #fff;
    border-radius: 0px 0px 14px 0px;
  }

  &:after {
    display:none;
  }

}

@media (min-width:992px) {
  
  header .navbar .container .inner-container.container .navbar-nav .nav-item:nth-child(6) a {
    padding: 0px 9px 3px 9px;
    border-radius: 0px 0px 9px 0px;
    transition: 0.4s ease;

    &:hover {
      background-color:#fff;
      color: #112342;
    }
  }

  header.sticky .navbar .container .inner-container.container .navbar-nav .nav-item:nth-child(6) a {
    &:hover {
      background-color:#FDDB40;
      color: #112342;
    }
  }

}

@media (min-width:992px)and(max-width:1300px){
  header .navbar .container{
    padding-left:0px;
    padding-right:0px;
  }
  .navbar-brand img {
    height: 45px;
    width: auto;
  }
  .inner-container .nav-item{
      padding: 0px 32px 20px 7px;
  }
}


</style>