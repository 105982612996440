<template>
  <div id="app">
    <the-header></the-header>
    <router-view></router-view>
    <cookie-banner></cookie-banner>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
import CookieBanner from './components/CookieBanner.vue'

export default {
  components: { 
    TheFooter, 
    TheHeader,
    CookieBanner
  },
  metaInfo: {
      title: 'Theoretical and Scientific Data Science (TSDS) — SISSA',
      htmlAttrs: {
        lang: 'en',
      }
    },
  data: () => {
    return {
    }
  },
  methods:{
  },
  computed: {
  },
  mounted(){
    window.scrollTo(0, 0)
  },
  watch: {
  }
}
</script>

<style lang="scss">
@import "common.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.showCookie{
  &>div{
    display: none;
  }
  &>div.cookie{
    display: block;
  }
}
.showPrivacy{
  &>div{
    display: none;
  }
  &>div.privacy{
    display: block;
  }
}

.showCovid{
  &>div{
    display: none;
  }
  &>div.covid{
    display: block;
  }
}
</style>
