<template>
    <div class="header-intro"  :style="this.background">
        <div class="container">
            <h1>
                {{ this.title }}
            </h1>
            <h2 v-if="this.subtitle" v-html="this.subtitle">
            </h2>
        </div>
    </div>
</template>
<script>
export default {
    name: 'header-intro',
    props: {
        title: String,
        subtitle: String,
        backimg: String
    },
    computed: {
        background() {
            return 'background: linear-gradient(0deg,' +
                'rgb(0, 0, 0, 0.6) 0%,' +
                'rgba(0, 0, 0, 0.4) 38%,' +
                'rgba(0, 0, 0, 0.4) 63%,' +
                'rgba(0, 0, 0, 0.6) 100%),' +
                'url("/filigrana-squares-header.png") no-repeat right bottom,' +
                'url("/pattern-overlay-header.png"),' +
                'url('+ this.backimg + ') no-repeat center center / cover;' 
        }
    },
}
</script>

<style lang="scss">
    .header-intro {
        padding: 2rem 0rem 8rem 0rem;
        .container {
            padding-top: 11rem;
            padding-bottom: 2rem;
        }
        h1 {
            min-height: 94px;
            padding-bottom: 0px;
        }
        h2 {
            max-width: 1000px;
            padding: 0;
        }
    }
    @media (max-width:1400px) {
        .header-intro {
            .container {
                padding-top: 7rem;
                padding-bottom: 2rem;
                h1 {
                    min-height: 90px;
                }
            }
        }
    }
    @media (max-width:992px) {
        .header-intro {
            .container {
                padding-top: 6rem;
            }
            h1 {
                min-height: 79px;
            }
        }
    }
    @media (max-width:768px) {
        .header-intro {
            padding: 0;
            .container h1 {
                min-height: 58px;
            }
        }
    }
</style>