ù<template>
    <div class="cookie-banner">
        <vue-cookie-accept-decline :elementId="'myPanel1'" :ref="'myPanel1'" :debug="false" :type="'floating'"
            :position="'bottom-right'" :disableDecline="true" :transitionName="'slideFromBottom'"
            :showPostponeButton="false" :forceCookies="false" @status="cookieStatus"
            @clicked-accept="cookieClickedAccept" @clicked-decline="cookieClickedDecline">

            <div slot="postponeContent">
                &times;
            </div>

            <!-- Optional -->
            <div slot="message">
                <div>
                    <h3>{{ this.title }}</h3>
                    <p v-html="this.text"></p>
                </div>
            </div>

            <!-- Optional -->
            <div slot="acceptContent">
                <span>ACCEPT</span>
            </div>

            <!--<div slot="declineContent">
                <span>Rifiuto</span>
            </div>-->
        </vue-cookie-accept-decline>
    </div>
</template>

<script>
    import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
    import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';

    export default {
        name: 'cookie-banner',
        components: {
            VueCookieAcceptDecline
        },
        data: () => {
            return {
                acceptedCookie: false,
                status: null,
                title: "Cookie policy",
                text: `<p>This site does not use profiling cookies, but only its own and third-party technical cookies, also linked to the presence of social plug-ins. To learn more and disable all or some cookies, visit the Cookie Policy page. By continuing to browse without changing the settings and by clicking on "ACCEPT", you are consenting to the use of cookies.</p>`,
            }
        },
        methods: {
            cookieStatus(status) {
                console.log('status: ' + status)
                this.status = status
            },
            cookieClickedAccept() {
                console.log('cookies accepted')
                this.status = 'accept'
            },
            cookieClickedDecline() {
                console.log('cookies declined')
                this.status = 'decline'
            },
            cookieRemovedCookie() {
                console.log('removed cookies')
                this.status = null
                this.$refs.myPanel1.init()
            },
            removeCookie() {
                console.log('removed cookies')
                this.$refs.myPanel1.removeCookie()
            },
        },
        mounted() {}
    }
</script>

<style lang="scss">
    @import "../styles/colors.scss";

    .cookie__floating__content {

        max-height: none;
    }

    button.cookie__floating__buttons__button {
        background-color: transparent;
        border: 0 !important;
        padding: 13px 10px 13px 10px;

        max-width: 120px;
        border-radius: 0px 0px 11px 0px;
        color: #2d3e5f;
        font-size: 14px;

        &:hover {
            background-color: transparent;
            text-decoration: underline;
        }

        &:before {
            display: none;
        }

        span {
            font-weight: 600;
            color: #2d3e5f;
            text-decoration: underline;
        }
    }

    .cookie {
        position: fixed;
        box-shadow: none;
        z-index: 99;
        border-radius: 0px 0px 11px 0px;
        background: #fddb40;
        bottom: 15px;
        width: 500px;
        display: block;
        max-width: none;
        overflow: visible;
        max-height: none;
        margin: 0px auto;
        border: 0px;
        height: auto;
        color: #1c283e;

        h3 {
            font-size: 1rem;
            padding: 0;
            margin-bottom: 1rem;
            font-weight: 600;
            color: #1c283e !important;
        }

        p {
            font-size: 0.8rem;
            line-height: 1.4rem;
            color: #1c283e;
        }

        .cookie__floating__content {
            padding: 25px 30px 15px 30px;
        }

        .cookie__floating__postpone-button {
            display: flex; display: -webkit-flex;
            flex-direction: row;
            justify-content: flex-end;
            padding-right: 20px;

            div {
                color: #fff;
            }
        }

        .cookie__floating__buttons {
            display: flex; display: -webkit-flex;
            flex-direction: row;
            justify-content: flex-end;
        }
    }
    .cookie__floating__content {
    margin-bottom: 0px;
}

    .cookie__floating__content {
        overflow: visible;
        padding: 15px 30px;
    }

    @media (max-width:992px) {
        .cookie.cookie__floating.cookie__floating--bottom-right {
            width: calc(100% - 30px);
            margin: 0px auto;
            left: 50%;
            transform: translateX(-50%);
        }

        .cookie__floating__content {
            padding-right: 150px;
        }
    }

    @media (max-width:768px) {
        .cookie.cookie__floating.cookie__floating--bottom-right {
            width: 100%;
            margin: 0px auto;
            left: 50%;
            padding: 0.9rem 0rem 1rem 0rem;
            transform: translateX(-50%);
            border-radius: 0px;
            bottom: 0;
        }

        .cookie.cookie__floating.cookie__floating--bottom-right:before {
            display: none;
        }

        .cookie__floating__content {
            overflow: auto;
        }
    }
</style>