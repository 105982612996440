<template>
    <div class="footer">
        <div class="sitemap">
            <div class="container">
                <div class="footer-column" v-for="(item, index) in this.menuItems" :key="'footer' + index">
                    <div v-if="item.children!=undefined">
                        <h4>{{ item.label }}</h4>
                        <router-link v-for="(child, index) in item.children" :key="item.label + 'child' + index"
                            :to="child.url">
                            <a> {{ child.label }} </a>
                        </router-link>
                    </div>
                    <div v-else>
                        <router-link :to="item.url">
                            <h4>{{ item.label }}</h4>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="contacts">
            <div class="container">
                <div>
                    <div class="loghi">
                        <a href="/"><img src="../assets/logo-dss-footer.svg" alt=""></a>
                        <a href="https://www.sissa.it/" target="_blank" ><img src="../assets/logo-sissa-white.png" alt=""></a>
                    </div>
                    <div class="adress">
                        <h6>
                            SISSA DATA SCIENCE <br>
                            Machine Learning for the Natural Sciences
                        </h6>
                        <p>
                            Via Bonomea 265<br>
                            34136 Trieste<br>
                            ITALY<br>
                            Codice Fiscale 80035060328<br>
                        </p>
                    </div>
                    <div class="for-students">
                        <router-link class="cta" to="/contacts">
                            <span>Contact Us</span>
                        </router-link>
                        <div>
                            <p>TEL: (+39) 040 3787111</p>
                            <a href="mailto:info@sissa.it">info@sissa.it</a>
                            <a href="mailto:protocollo@pec.sissa.it">PEC: protocollo@pec.sissa.it</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright">
            <div class="container">
                <p>Copyright © 2021 SISSA DATA SCIENCE - All rights reserved
                    <span>
                        <a href="/cookie-policy">Cookie Policy</a> | <a href="/privacy-policy">Privacy Policy</a>
                    </span>
                </p>
                <a class="icon" href="https://backend.datascience.sissa.it/user" target="_blank" >
                    Reserved Area
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'the-footer',
        data: () => {
            return {
                menuItems: []
            }
        },
        methods: {
            async fetchMenuItems() {
                var menu = []
                var items = []
                var ch = []

                await fetch(`${process.env.VUE_APP_ENDPOINT}/jsonapi/menu_items/front-menu`)
                    .then(res => res.json())
                    .then(json => items = json.data)

                //for each fetched route
                items.forEach(item => {
                    ch = []

                    //for each menu item with no parent
                    if (item.attributes.parent === "" || item.attributes.parent === undefined) {
                        //if the number of its children is 0
                        if (items.filter(it => it.attributes.parent === item.id).length == 0) {
                            //push it into the menu as a top-level route
                            menu.push({
                                label: item.attributes.title,
                                url: item.attributes.url
                            })

                        } else {
                            //else search for its children
                            items.filter(it => it.attributes.parent === item.id)
                                //and create an array of routes
                                .forEach(child => ch.push({
                                    label: child.attributes.title,
                                    url: child.attributes.url
                                }))
                            //then push them into the menu as a sub-level route (for that corresponding top-level menu)
                            menu.push({
                                label: item.attributes.title,
                                children: ch
                            })
                        }
                    }
                })

                //create the menu
                this.menuItems = menu
                //console.log(menu)

            },
        },
        computed: {
            linkLogin(){
                return process.env.VUE_APP_ENDPOINT + "/user"
            }
        },
        mounted() {
            this.fetchMenuItems();
        }
    }
</script>

<style scoped lang="scss">
    @import "../styles/colors.scss";

    .sitemap {
        background-color: #1C283E;
        padding: 1rem 0rem;

        .container {
            display: flex;
            display: -webkit-flex;
            flex-direction: column;

            @media (min-width: 992px) {
                flex-direction: row;
            }

            justify-content: flex-start;
        }

        div {
            display: flex;
            display: -webkit-flex;
            flex-direction: column;
        }

        .footer-column {
            width: 20%;
            margin: 0;
            margin-right: 2em;

            &:last-child {
                margin: 0;
            }
        }

        h4 {
            color: #fff;
            font-weight: 700;
            margin: 1rem 0rem;
        }

        a {
            color: #C9CFD9;
            padding: 6px 0px;
            font-size: 0.875rem;
            text-decoration: none;
        }

        a:hover {
            color: $mainColor;
        }
    }

    .copyright a:hover {
        color: $mainColor;
    }

    .contacts {
        .container {
            &>div:first-child {
                display: flex;
                display: -webkit-flex;
                align-items: center;

                .loghi {
                    width: 52%;
                    display: flex;
                    display: -webkit-flex;
                    justify-content: space-between;
                }
            }

            img {
                height: 150px;
            }
        }

        .adress {
            border-left: 1px solid #d5d5d51a;
            border-right: 1px solid #d5d5d51a;
            width: 24%;

            h6 {
                line-height: 1.4rem;
                font-weight: 700;
                font-size: 1rem;
                color: $mainColor;
                margin-bottom: 20px;
            }

            p {
                line-height: 1.4rem;
                font-weight: 400;
                font-size: 0.9375rem;
                color: #fff;
                margin-bottom: 20px;
            }

            a {
                display: block;
                color: $mainColor;
            }
        }

        .for-students {
            width: 24%;

            a {
                text-decoration: none;
                display: inline-block;
            }

            p {
                margin-top: 20px;
                line-height: 1.2rem;
                font-size: 0.9375rem;
                margin-bottom: 7px;
                color: #ffffff;
            }

            div a {
                display: block;
                color: $mainColor;
                font-size: 0.9375rem;
                margin-bottom: 7px;
            }
        }
    }

    .copyright {
        background-color: #121E33;
        border-top: 1px solid #d5d5d51a;
        font-size: 0.8125rem;
        padding: 1rem 0rem;

        .container {
            width: 100%;
            display: flex;
            display: -webkit-flex;
            justify-content: space-between;
        }

        p {
            font-size: 0.8125rem;
            color: #7B8494;
        }

        a {
            text-decoration: none;
            color: #fff;
        }

        span {
            margin-left: 30px;
        }

        a.icon {
            position: relative;

            &:before {
                position: absolute;
                content: url("../assets/icon-lock-yellow.svg");
                display: block;
                width: 14px;
                height: 19px;
                top: 0;
                left: -28px;
            }
        }
    }

    .contacts {
        background-color: #121E33;

        .loghi,
        .adress,
        .for-students {
            padding: 2rem 3rem;
        }

        .loghi {
            padding-left: 0;
        }
    }

    @media (max-width:1500px) {
        .contacts {
            background-color: #121E33;

            .adress,
            .for-students {
                padding: 2rem 2rem;
            }

            .container {
                div:first-child {
                    .loghi {
                        width: 48%;
                    }

                    .adress {
                        width: 30%;
                    }
                }

                img {
                    height: 110px;
                }
            }
        }
    }

    @media (max-width:1200px) {
        .contacts {
            .container {
                img {
                    width: auto;
                    height: 80px;
                }
            }
        }
    }

    @media (max-width:1024px) {
        .sitemap .container {
            flex-direction: row;
            flex-wrap: wrap;

            .footer-column {
                width: 33.3%;
                margin: 1em 0;
            }
        }

        .contacts .container {
            &>div:first-child {
                flex-direction: column;

                .loghi,
                .adress,
                .for-students {
                    width: 100%;
                    padding-right: 0;
                    padding-left: 0;
                }

                .loghi {
                    justify-content: flex-start;

                    img {
                        margin-right: 4rem;
                    }
                }

                .adress {
                    border-right: 0px;
                    border-left: 0;
                    border-top: 1px solid #d5d5d51a;
                    border-bottom: 1px solid #d5d5d51a;
                }
            }
        }
    }

    @media (max-width:768px) {
        .sitemap .container {
            display: block;

            .footer-column {
                width: 100%;
            }
        }

        .copyright .container {
            display: block;

            a {
                white-space: nowrap;
            }

            span {
                margin-left: 0px;
            }

            a.icon {
                position: relative;
                padding: 20px 10px 18px 33px;
                display: block;

                &:before {
                    top: 18px;
                    left: 0px;
                }
            }
        }
    }

    @media (max-width:576px) {
        .contacts .loghi {
            img {
                height: 58px;
            }
        }
    }
</style>