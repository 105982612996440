<template>
    <div class="template-page mission">
        <basic-page :fulldata="fulldata" :pagedata="this.pageData" :bannerimg="this.bannerimg">
            <h1>Downloading sitemap.xml ...</h1>
        </basic-page>
    </div>
</template>

<script>
import BasicPage from './BasicPage.vue'
export default {
    name: "sitemap",
    components: { BasicPage },
    data: () => {
        return {
            routesData: [],
            xmltext: "",
            pageData: {},
            fulldata: {},
            bannerimg: null
        }
    },
    methods: {},
    computed: {},
    async mounted() {
        
        await fetch(process.env.VUE_APP_ENDPOINT + "/json/sitemap")
        .then(res => res.json())
        .then(json => this.routesData = json.data.routes )

        //console.log(this.routesData)

        this.xmltext = `<?xml version="1.0" encoding="UTF-8"?> <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">`;

        this.routesData.forEach(r => {
            this.xmltext += `<url> <loc>` + process.env.VUE_APP_ENDPOINT + r.path  + `</loc> </url>`
        })

        this.xmltext += `</urlset>`

        var filename = "sitemap.xml";
        var pom = document.createElement("a");
        var bb = new Blob([this.xmltext], {type: "text/plain"});

        pom.setAttribute("href", window.URL.createObjectURL(bb));
        pom.setAttribute("download", filename);

        pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(":");
        pom.draggable = true; 
        pom.classList.add("dragout");

        pom.click()

        this.$router.push('/')
    }
}
</script>

<style lang="scss" scoped>
</style>