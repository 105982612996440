import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueGtag from "vue-gtag";
import { router } from './router/index'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(Meta)

router().then(router => {
  Vue.use(VueGtag, {
    config: { id: "G-Q57DHP1C2Y" }
  }, router); 

  Vue.config.productionTip = false

  new Vue({
    router,
    store,
    render: (h) => h(App)
  }).$mount("#app")

})