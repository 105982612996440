import Vue from 'vue'
import VueRouter from 'vue-router'
import { fetchRoutes } from '../libs/drupalClient'
import NotFound from '../views/NotFound.vue'
import Sitemap from '../views/Sitemap.vue'

Vue.use(VueRouter)

export function router() {
  return fetchRoutes().then(data => {
    /* console.log(data) */

    var fetchedRoutes = data.routes
    fetchedRoutes.forEach(f => {
      f.component = () => import(/* webpackChunkName: "drupal-custom-component" */ '../views/' + f.name + '.vue')
    })

    fetchedRoutes.push({
      path: '/not-found',
      name: 'NotFound',
      component: NotFound
    })

    fetchedRoutes.push({
      path: '/sitemap.xml',
      alias: '/sitemap',
      name: 'Sitemap',
      component: Sitemap
    })

    //adding as alias doe not change url in browser, redrect sends to correct page
    fetchedRoutes.push({
      path: '/apply',
      redirect: '/how-to-apply'
    })

    //route default per pagine/routes non esistenti 404
    fetchedRoutes.push({
      path: '*',
      redirect: '/not-found'
    })

    /* console.log(fetchedRoutes) */

    return new VueRouter({
      scrollBehavior() {
        return { x: 0, y: 0 };
      },
      mode: 'history',
      base: process.env.BASE_URL,
      routes: fetchedRoutes
    })
  })
}
